<template>
	<div :class="themeClass">
		<el-dialog v-dialogDrag title="新增银行卡" :visible.sync="addBankCardVisible" width="540px" :before-close="handleClose"
		 :close-on-press-escape="false" :close-on-click-modal="false">
			<div ref="dialogBox" class="dialogScroll">
				<el-form ref="form" :rules="rules" :model="form" label-width="150px" label-position="right">
					<el-form-item label="持卡人姓名" prop="name">
						<el-input v-model="form.name" />
					</el-form-item>
					<el-form-item label="身份证号" prop="idCard">
						<el-input v-model="form.idCard" />
					</el-form-item>
					<el-form-item label="银行账号" prop="bankCard">
						<el-input v-model.number="form.bankCard" placeholder="请输入银行账号"></el-input>
					</el-form-item>
					<el-form-item label="开户行" prop="fBankTypeName" class="bank-type">
						<el-select v-model="form.fBankTypeName" placeholder="请选择开户行" ref="fBankTypeName">
							<el-option style="width: 330px;" v-for="(item,index) in bankTypeList" :key="index" :label="item.fBankTypeName" :value="item.fBankTypeName"></el-option>
						</el-select>
					</el-form-item>
					<el-form-item label="银行预留号" prop="bankTel">
						<el-input v-model.number="form.bankTel" placeholder="请输入预留手机号码"></el-input>
					</el-form-item>
					<el-form-item label="验证码" prop="Perfectregister">
						<el-input v-model="form.Perfectregister" placeholder="请输入正确的验证码">
							<span slot="suffix" class="font-size12 color-theme verification-button pointer flex-row-center-center" @click="countDown">{{getYZ}}
								<span>{{miao}}</span></span>
						</el-input>
					</el-form-item>
					<el-form-item label="设为默认快捷支付">
						<el-switch v-model="form.default" />
					</el-form-item>
				</el-form>
			</div>
			<span slot="footer" class="dialog-footer">
				<el-button size="small" @click="handleClose">取 消</el-button>
				<el-button size="small" type="primary" @click="submitForm">确 定</el-button>
			</span>
		</el-dialog>
	</div>
</template>

<script>
	import {
		mapGetters
	} from 'vuex';
	export default {
		props: {
			addBankCardVisible: {
				type: Boolean,
				default: false
			},
		},
		data() {
			const validateIDCard = (rule, value, callback) => {
				if (!Verification.Verification.VerifyIDCard(value)) {
					callback(new Error(Verification.Verification.IDCardInfo));
				} else {
					callback();
				}
			};
			const validatePhoneNumber = (rule, value, callback) => {
				if (!Verification.Verification.IsPhoneNumber(value)) {
					callback(new Error(Verification.Verification.PhoneNumberInfo));
				} else {
					callback();
				}
			};
			return {
				getYZ: "获取验证码",
				miao: '',
				isAdd: false, //是否新增银行卡
				bankTypeList: [], //开户行
				form: {
					name: '杰尼', //姓名
					idCard: 6544444444444444, //身份证号
					bankCard: 654654654654654, //银行卡号
					fBankTypeName: '', //开户行
					bankTel: 18899695153, //预留手机号
					default: true, //设置为默认支付方式
					Perfectregister: '' //验证码
				},
				rules: {
					name: [{
							required: true,
							message: '请输入真实姓名',
							trigger: 'change'
						},
						{
							pattern: /^([\u4E00-\u9FFF]+)(·?[\u4E00-\u9FFF]*)$/,
							// 正则检验前先将值转为字符串
							transform(value) {
								return String(value);
							},
							message: '请输入真实姓名'
						}
					],
					idCard: [{
						required: true,
						message: '请输入身份证号',
						trigger: 'blur'
					}, {
						validator: validateIDCard,
						trigger: "blur"
					}],
					bankCard: [{
						required: true,
						message: '请输入银行卡号',
						trigger: 'blur'
					}],
					fBankTypeName: [{
						required: true,
						message: '请选择开户行',
						trigger: 'change'
					}],
					bankTel: [{
						required: true,
						message: '请输入正确的手机号',
						trigger: 'blur'
					}, {
						type: 'number',
						message: '移动电话号必须为数字'
					}, {
						validator: validatePhoneNumber,
						trigger: "blur"
					}],
					Perfectregister: [{
						required: true,
						message: '请输入验证码',
						trigger: 'blur'
					}],
				},
			}
		},
		computed: {
			...mapGetters(['getThemeName']),
			themeClass() {
				return `theme-${this.getThemeName}`;
			}
		},
		mounted() {
			this.getBankType();
		},
		methods: {
			//获取开户行
			getBankType() {
				this.ApiRequestPost('api/mall/ebbase/bank-type/get-onstatus-list', {})
					.then(res => {
						// console.log('获取开户行', res);
						this.bankTypeList = res.obj.items;
					}, error => {

					});
			},
			// 新增验证事件
			submitForm() {
				this.$refs.form.validate((valid) => {
					if (valid) {
						this.addSubmit()
					} else {
						return false
					}
				})
			},
			//获取验证码
			countDown() {
				this.getYZ = 30;
				this.miao = "S";
				let time = setInterval(() => {
					this.getYZ--;
					if (this.getYZ == 0) {
						clearInterval(time);
						this.miao = "";
						this.getYZ = "获取验证码";
					}
				}, 1000);
			},
			// 新增提交事件
			addSubmit() {
				// this.ApiRequestPost('/api/mall/ebactivity/invited-rule-bill/create',
				// 		this.form
				// 	)
				// 	.then(result => {
				// 			// this.$nextTick(() => {
				// 			//   this.$refs["form"].resetFields();
				// 			// })
				// this.isAdd = true;
				// 			this.handleClose()
				// 		},
				// 		rej => {
				// 			this.handleClose()
				// 		})
				this.isAdd = true;
				this.handleClose()
			},
			// 新增弹窗关闭事件
			handleClose() {
				this.$emit('change', !this.addBankCardVisible, this.isAdd)
				this.isAdd = false;
			},
		}
	}
</script>

<style scoped="scoped" lang="scss">
	.color-theme {
		@include themify($themes) {
			color: themed('themes_color');
		}
	}


	.background-color-theme {
		@include themify($themes) {
			background-color: themed("themes_color");
			color: themed("text_color_white");
		}
	}

	.verification-button {
		width: 82px;
	}

	// .el-select ::v-deep {
	// 	.el-select-dropdown {
	// 		width: 330px;
	// 	}
	// }

	::v-deep .el-input {
		width: 330px;
	}
</style>

<!-- 账服通银行选择 -->
<template>
	<div :class="themeClass">
		<el-dialog v-dialogDrag title="选择账服通银行" :visible.sync="getAcsBankVisible" width="792px" :before-close="handleClose"
		 :close-on-press-escape="false" :close-on-click-modal="false" v-loading='loading'>
			<div ref="dialogBox" class="dialogScroll">
				<div class="flex-row-wrap padding-lr-15">
					<div v-for="(item,index) in bankTypeList" :class="(index+1)%4==0?(item.checked?'bank-list-box-true':'bank-list-box'):(item.checked?'bank-list-box-true margin-r-10':'bank-list-box margin-r-10')"
					 class="margin-b-10 flex-row-align-center pointer" @click="selectBank(item,index)">
						<imageBox class='user-img margin-r-10' :width='20' :height='20' :borderRidus='20' :src="item.fIconPath"></imageBox>
						<div class="font-size13 font-color-333 bank-name hide-text">{{item.fBankTypeName}}</div>
					</div>
				</div>
			</div>
			<span slot="footer" class="dialog-footer">
				<el-button size="small" @click="handleClose">取 消</el-button>
				<el-button size="small" type="primary" @click="submitForm">确 定</el-button>
			</span>
		</el-dialog>
	</div>
</template>

<script>
	import {
		mapGetters
	} from 'vuex';
	import imageBox from '@/components/imageBox/imageBox.vue'
	export default {
		components: {
			imageBox
		},
		props: {
			getAcsBankVisible: {
				type: Boolean,
				default: false
			},
		},
		data() {
			return {
				isSelect: false, //是否选择银行
				selectBankData: {}, //选择银行数据
				bankTypeList: [], //账服通银行
				loading: false //等待
			}
		},
		computed: {
			...mapGetters(['getThemeName']),
			themeClass() {
				return `theme-${this.getThemeName}`;
			}
		},
		mounted() {
			this.getBankType();
		},
		methods: {
			//获取账服通银行
			getBankType() {
				this.loading = true;
				this.ApiRequestPost('api/mall/ebbase/bank-type/get-acs-banktype', {})
					.then(res => {
						// console.log('获取账服通银行', res);
						this.bankTypeList = res.obj.items;
						for (let item of this.bankTypeList) {
							item.checked = false;
						}
						this.loading = false;
					}, error => {
						this.loading = false;
					});
			},
			selectBank(item, index) {
				// console.log(item, index);
				this.bankTypeList.forEach((item1, index1) => {
					item1.checked = false;
				});
				this.selectBankData = item;
				item.checked = true;
				this.$forceUpdate()
			},
			// 新增验证事件
			submitForm() {
				this.isSelect = true;
				this.handleClose()
			},
			// 新增弹窗关闭事件
			handleClose() {
				let obj = {
					show: !this.getAcsBankVisible,
					isSelect: this.isSelect,
					selectBank: this.selectBankData
				}
				this.$emit('change', obj)
				this.isSelect = false;
			},
		}
	}
</script>

<style scoped="scoped" lang="scss">
	.color-theme {
		@include themify($themes) {
			color: themed('themes_color');
		}
	}


	.background-color-theme {
		@include themify($themes) {
			background-color: themed("themes_color");
			color: themed("text_color_white");
		}
	}

	.verification-button {
		width: 82px;
	}

	.bank-list-box {
		width: 158px;
		height: 38px;
		padding: 0 10px;
		border: 1px solid #F2F2F2;
	}

	.bank-list-box-true {
		width: 158px;
		height: 36px;
		padding: 0 9px 0 9px;

		@include themify($themes) {
			border: 2px solid themed('themes_color');
		}
	}

	.bank-list-box:hover {
		width: 158px;
		height: 36px;
		padding: 0 9px 0 9px;

		@include themify($themes) {
			border: 2px solid themed('themes_color');
		}
	}

	.bank-name {
		white-space: nowrap;
		user-select: none;
	}

	::v-deep .el-input {
		width: 330px;
	}
</style>

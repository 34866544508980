<!-- 支付中心 -->
<template>
  
  <div :class="themeClass ">
    <div style="width: 100%;background-color: #FFFFFF;" >
      <div class="login-t flex-row-space-between-center">
        <div class="flex-row-align-center">
          <div class="font-weight700 font-size18 pointer" @click="goHome"><img :src="logoUrl" style="height: 54px;" />
          </div>
          <div class="font-color-fff font-weight700 font-size14 bg-E30000 flex-row-center-center pay-center">支付中心</div>
        </div>
        <div class="flex-row font-size12 font-color-333 font-weight400">
          <!-- <router-link to="/HelpCenter">帮助中心</router-link> -->
          <div class='pointer margin-r-10' @click="toAllOrder">我的订单</div>
          <div class='pointer' @click="toHelpCenter">帮助中心</div>
        </div>
      </div>
    </div>
    <div class="loginh" :class="wxpayshow ? 'hiddenpage' : ''" style="padding-top: 17px;">
      <div class="login-c ">
        <div class=" box-shadow-all bg-fff padding-15" style="border-radius: 10px;" v-loading='orderLoading'>
          <!-- 充值提现表 -->
          <div v-if="pageType==2" class="padding-lr-15 reacharge-or-refund-box flex-row-space-between-center">
            <div class="font-size18 font-color-333 font-weight700">{{pageTitle}}</div>
            <div class="flex-row-center-center" style="height: 45px;">
              实付金额:
              <p v-if="fIsCashRecharge==1" class="font-color-E30000 font-weight700 font-size22">
                ￥{{ payData.fSumMoney|NumFormat  }}</p>
              <p v-else class="font-color-E30000 font-weight700 font-size22">￥{{ payData.fTotalMoney|NumFormat  }}</p>
              元
            </div>
          </div>
          <!-- 订单列表 -->
          <div v-else>
            <div class="font-size14 font-weight700 margin-b-10 margin-t-10 margin-l-15">{{pageTitle}}</div>
            <div class=" margin-lr-15">
              <div class="flex-row-space-between-center font-size14" style="height: 30px;background: #f8f9fe;">
                <div style="margin-left: 119px;">商品</div>
                <div class="flex-row">
                  <div style="margin-right: 129px;">数量</div>
                  <div style="margin-right: 129px;">价格</div>
                  <div style="margin-right: 63px;">合计</div>
                </div>
              </div>
              <div v-for="(subItem, subIndex) in payList" :key="subIndex"
                class="flex-row-space-between-center border-bottom-F2F2F2" style="height: 37px;">
                <div class="flex-row-start-start">
                  <div style="margin-left: 19px;">
                    <div class="">{{ subItem.fGoodsFulleName }}</div>
                  </div>
                </div>
                <div class=" ">
                  <div class="flex-row">
                    <div class="flex-row-center-center  "
                      style="border-left: 1px solid #eaebef;height: 16px; width: 155px;">
                      <p class="font-color-666">{{ subItem.fAmount }}{{ subItem.fUnitName }}</p>
                    </div>
                    <div class="flex-row-center-center  "
                      style="border-left: 1px solid #eaebef;height: 16px; width: 155px;">
                      <p class="font-color-666">￥{{ subItem.fPrice|NumFormat  }}&nbsp;&nbsp;元/{{subItem.fUnitName}}</p>
                    </div>
                    <div class="flex-row-center-center  "
                      style="border-left: 1px solid #eaebef;height: 16px; width: 155px;">
                      <p class="font-weight700">￥{{subItem.fMoney - subItem.fFreightMoney - subItem.fTransportServiceMoney |NumFormat  }} </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div style="height: 120px;" class="flex-row-space-between-center  margin-t-10">
              <div class="flex-row-center-center padding-15">
              </div>
              <div class="flex-column-start-end font-size13 " style="margin-right: 15px;line-height: 22px;">
                <div class="font-color-999 flex-row-space-between-center" style="width: 200px;">
                  商品金额：
                  <div>+ ¥{{ payData.totalGoodsMoney - payData.ffMoneyReceivableTotal |NumFormat  }}</div>
                </div>
                <div class="font-color-999 flex-row-space-between-center" style="width: 200px;">
                  运费金额：
                  <div>+ ¥{{ payData.ffMoneyReceivableTotal|NumFormat  }}</div>
                </div>
                <div v-if="fBillTypeID != '200016002'&&fBillTypeID != '200013013'"
                  class="font-color-999 flex-row-space-between-center" style="width: 200px;">
                  优惠券抵扣：
                  <div>- ¥{{ payData.fTotalCouponMoney|NumFormat  }}</div>
                </div>
                <div v-if="fBillTypeID != '200016002'&&fBillTypeID != '200013013'"
                  class="font-color-999 flex-row-space-between-center" style="width: 200px;">
                  积分抵扣：
                  <div>- ¥{{ payData.fTotalPointMoney|NumFormat  }}</div>
                </div>
                <div class="flex-row-center-center" style="height: 45px;">
                  实付金额:
                  <p class="font-color-E30000 font-weight700 font-size22">￥{{ payData.fTotalMoney|NumFormat  }}</p>
                  元
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- 支付方式 -->
        <div class="boxStyle" v-loading='paymentLoading||orderLoading'>
          <div style="padding-top: 1px;">
            <div class="background-color-theme flex-row-center-center capsule font-weight700" style="margin-top: 14px;">
              请选择支付方式</div>
            <div v-for="(item, index) in paymentList" :key="index" class="price-box flex-row-align-center  pointer"
              :class="item.checked ? 'active' : 'none'" @click="paymentListChoose(item, index)">
              <div class="flex-row-space-between-center width-fill">
                <div class="width-fill" style="margin-left: 18px;display: flex;align-items: center;">
                <div class="flex-row-align-center">
                    <el-checkbox v-model="item.checked" @change="paymentListChoose(item, index)"></el-checkbox>
                    <div class="margin-rl-15 flex-row-center-center "
                      style="width: 32px;height: 32px;border-radius: 5px;" :style="'background-color:'+(item.fSettleModeID==4 ? 'rgba(0,0,0,0)' : item.color)">
                      <div v-if="item.fSettleModeID==6||item.fSettleModeID==1"
                        class="font-size25 iconfont font-color-fff" :class="item.icon"></div>
                      <div v-else-if="item.fSettleModeID==14||item.fSettleModeID==16"
                        class="font-size23 iconfont font-color-fff" :class="item.icon"></div>
                      <div v-else-if="item.fSettleModeID==4 || item.fSettleModeID==11" >
                        <img src="@/assets/zhifulogo.png" style="width: 90%; height: 90%;" alt="">
                      </div>
                      <div v-else-if="item.fSettleModeID==12 || item.fSettleModeID==19" >
                        <img src="@/assets/zhifubaopay.png" style="width: 90%; height: 90%;" alt="">
                      </div>
					  <div v-else-if="item.fSettleModeID==20" >
					    <img src="@/assets/zhaoshang.png" style="width: 90%; height: 90%;" alt="">
					  </div>
                      <div v-else class="font-size20 iconfont font-color-fff" :class="item.icon"></div>
                    </div>
                    <div class="font-size14 font-color-000 bg-fff" style="padding: 10px 0;">
                      <div class="font-weight700 font-color-000" style="display: flex;align-items: center;">
					  {{ item.fSettleMode }}
					  <div v-if='item.fSettleModeID == 2'   style="display: flex;justify-content: center">
					  	<el-tooltip placement="right">
					  		<div slot="content">
					  			中金支付为聚材通平台支持的第三方支付渠道，用户可通过中金支付向聚材通平台充值及支付货款。<br/>
					  		</div>
					  		<div class="form-label-mark flex-row-center-center" style="font-weight: normal;">?</div>
					  	</el-tooltip>
					  </div>
					  </div>
                      <div class="font-color-999">
                        <span v-if="item.fSettleModeID==4 || item.fSettleModeID==11">亿万用户的选择，更快更安全</span>
                        <span v-if="item.fSettleModeID==20 && AccountPayWalletInfo">
							<span>余额:</span>
							&nbsp;&nbsp;
							<span v-if='showEndBalance' class="color-theme margin-l-10" @click.stop="Refresh(item)">
								查询余额
							</span>
							<span v-else>
								<span>￥&nbsp;{{item.fEndBalance|NumFormat}}</span>
								<span class="color-theme margin-l-10"
								  @click.stop="Refresh(item)"><i class="el-icon-refresh"></i>刷新</span>
							  </span>
						</span>
                        <span v-if="item.fSettleModeID==12 || item.fSettleModeID==19">10亿用户都在用，真安全，更放心</span>
                        <span v-if="item.fSettleModeID != 20">{{item.fEndBalance==null?item.PaymentMethodType:'余额:'}}</span>
                        &nbsp;&nbsp;
                        <span
                          v-if="item.fEndBalance!=null&&item.fSettleModeID!=14&&item.fSettleModeID!=16 && item.fSettleModeID != 20">￥&nbsp;{{item.fEndBalance|NumFormat}}</span>
                        <span v-if="item.fSettleModeID==14||item.fSettleModeID==16">{{item.fEndBalance}}</span>
                        <span v-if="(item.fSettleModeID==2||item.fSettleModeID==9)&&item.checked"
                          @click="showBankCardList=true">
                          <span v-if="selectBankCard.fBankName" class="margin-l-3 font-size13">
                            {{selectBankCard.fBankName}}
                          </span>
                          <span v-if="selectBankCard.bankCardWH" class="font-size13">
                            ({{selectBankCard.bankCardWH}})
                          </span>
                          <span v-if="bankCardList.length>0"
                            class="color-theme margin-l-3 iconfont icon-jixuduihuan font-size13">&nbsp;换卡
                          </span>
                        </span>
                        <span v-if="item.fEndBalance!=null && item.fSettleModeID != 20" class="color-theme margin-l-10"
                          @click.stop="Refresh(item)"><i class="el-icon-refresh"></i>刷新</span>
                      </div>
                    </div>
                </div>
                  <div v-if="item.fSettleModeID==10" class="margin-r-10 color-theme" style="margin-left: 30px;">
                    ⇋ {{selectZFTBank.fBankTypeName?'已选：'+selectZFTBank.fBankTypeName:'请选择银行'}}
                  </div>
                </div>
                <div class="font-color-999 flex-row" style="margin-right: 19px;">
                </div>
              </div>
            </div>
            <div class="flex-row-space-between padding-lr-20">
              <div class="font-color-999 flex-row" v-if="fBillTypeID!='200015019'&&fBillTypeID!='200000805'">
                <el-checkbox v-model="tradingRules"></el-checkbox>
                <div class="margin-l-10">我已阅读并同意</div>
                <div v-for="(item,index) in AgreementHistList" :key='index' class="color-theme pointer"
                  @click="showAgreeDia(item.fAgreementHistID)">《{{item.fTitle}}》</div>
              </div>
              <div class="color-theme margin-l-10 pointer" @click="hasProblem">
                支付常见问题?
              </div>
            </div>
            <div class="flex-row-space-between-center button-box-padding">
              <div class="button-box border-theme background-color-theme flex-row-center-center pointer"
                :disabled="payButtonLoading" v-loading='payButtonLoading' @click="PayDialog">
                去支付
              </div>
              <div v-if="showCountDowm" class="flex-row">
                <div class="font-size16 margin-r-10 font-weight700 font-color-333">剩余支付时间:</div>
                <countDowm :showHours='false' :remainTime="remainTime" :colonColor="'#333333'"
                  @countDownEnd="countDownEnd"></countDowm>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer></Footer>
    </div>
    <!-- 支付弹窗 -->
    
    <el-dialog v-dialogDrag :close-on-press-escape="false" title="请输入支付密码" :visible.sync="PasswordVisible" width="425px"
      :before-close="handleClose" :close-on-click-modal="false" :top="'40vh'">
      <div ref="dialogBox" class="font-size13 padding-b-35">
        <!-- <div class="flex-row-center-center password-box">
					<password-input v-if='PasswordVisible' @get-pwd="getPwd" />
				</div> -->
        <div class="flex-row-center-center">
          <div v-for="(v, i) in 6" :key="i" class="box-input-div flex-row-center-center margin-r-5"
            :class="(((i==0&&pwdList.length==0)||(i==5&&pwdList.length==6))?'box-input-div-choose-style':((i==pwdList.length)?'box-input-div-choose-style':'box-input-div-style'))">
            <div v-show="pwdList[i]" style="background-color: #606266;width: 15px;height: 15px;border-radius: 50%;">
            </div>
          </div>
        </div>
        <!-- <div>今日剩余次数</div> -->
      </div>
      <!-- <span slot="footer" class="dialog-footer">
				<el-button type="primary" size="small" @click="submitForm">确认</el-button>
				<el-button type="primary" size="small" @click="PasswordVisible=false">取消</el-button>
			</span> -->
    </el-dialog>
    <!-- 验证码弹窗 -->
    <el-dialog v-dialogDrag :close-on-press-escape="false" title="请输入验证码" :visible.sync="VerificationCodeVisible"
      width="425px" :before-close="VerificationClose" :close-on-click-modal="false" :show-close='false'>
      <div ref="dialogBox" class="dialogScroll font-size13 ">
        <div class="padding-20">
          <div class="font-size14 font-color-333 margin-b-10">银行预留手机号: {{selectBankCard.fPhone}}</div>
          <el-input placeholder="请输入验证码" v-model="VerificationCode" :maxlength='6'>
          </el-input>
        </div>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" size="small" @click="VerificationCodeVisible=false">取消</el-button>
        <el-button type="primary" size="small" v-loading='VerificationCodeLoading' @click="submitVerification">确认
        </el-button>
      </span>
    </el-dialog>
    <!-- 跳转支付弹窗 -->
    <el-dialog v-dialogDrag :close-on-press-escape="false" :visible.sync="paymentWaitingVerification" width="425px"
      :before-close="paymentWaitingClose" :close-on-click-modal="false" :show-close='false'>
      <div ref="dialogBox" class="dialogScroll">
        <div class="padding-20 flex-column-center-center">
          <div class="flex-row-center-center font-size20">
            <div class="flex-row-center-center payment-waiting-icon font-color-fff margin-r-10">￥</div>
            <div class="font-color-ff7777">支付结果确认中!</div>
          </div>
          <div class="font-size14 font-color-999 margin-t-15">我们已为您跳转至网银支付,请立即前往支付。</div>
          <div class="success-pay-button flex-row-center-center background-color-theme font-size14 pointer margin-t-25"
            @click="AlreadyPaid" :loading="paySuccess" :disabled="paySuccess">支付完成</div>
          <div class="font-size14 color-theme margin-t-10 pointer" @click="hasProblem">支付遇到问题?</div>
        </div>
      </div>
    </el-dialog>
    <!-- 选择快捷支付卡弹窗 -->
    <el-dialog v-dialogDrag :close-on-press-escape="false" title="请选择快捷支付卡" :visible.sync="showBankCardList"
      width="425px" :before-close="BankCardListClose" :close-on-click-modal="false">
      <div ref="dialogBox" class="dialogScroll padding-b-20">
        <div v-for="(item,index) of bankCardList" :key='index'
          class="flex-row-align-center border-bottom-F2F2F2 padding-l-10 pointer" style="height: 40px;"
          @click="chooseBankCard(item)">
          <div class="bank-icon flex-row-center-center">
            <svg-icon icon-class="UnionPay" />
          </div>
          <div class="margin-l-3">
            {{item.fBankName}}
          </div>
          <div>
            ({{item.bankCardWH}})
          </div>
        </div>
      </div>
    </el-dialog>
	<!--展示充值异常的弹框-->
	<el-dialog
	  title="提示"
	  :visible.sync="dialogVisible"
	  width="20%"
	  :close-on-click-modal = 'false'
	  :show-close ='false'
	  >
	  <span style="display: inline-block;padding: 15px;">{{byecontent}}</span>
	  <span  slot="footer" class="dialog-footer" style="display: inline-block;text-align: center;  width: 100%;">
		  <span style="display: inline-block;line-height: 42px;"  class="border-theme color-theme  pointer buttonStyle"
		   @click="returnHomepage">
		  	去首页
		  </span>
	  </span>
	</el-dialog>

  <!-- 扫码支付 -->
  <!-- <el-dialog v-dialogDrag :close-on-press-escape="false" :visible.sync="wxpayshow" width="375px" class="custom-dialog"
      :before-close="paymentWaitingClose" :close-on-click-modal="false" :show-close='false'>
      <div class="erweima">
        <img src="@/assets/zhifu.png" alt="" style="width: 148px; height: 148px;" class="img1">
        <img src="@/assets/zhifuicol.png" alt="" class="img2">
        <img src="@/assets/zhifuicor.png" alt="" class="img3">
        <div class="tit">
          <div style="font-weight: 700; font-size: 17px;">支付金额</div>
          <div style="font-weight: 700; color:#0173FE;">￥{{payData.fTotalMoney|NumFormat}} </div>
        </div>
        <div class="scan-code-box flex-row-center-center" style="margin-top: 30px;">
          <div class="logind flex-row-center-center">
            <div v-if="scanCodeStatus == 4" class="scan-code-error flex-column-center-center">
              <div class="font-size17 font-weight700 font-color-fff" style="position: absolute;">二维码已失效</div>
              
              <img src="@/assets/shandian2.png" alt="" style="width: 100%; height: 100%;">
            </div>
            <div v-if="fSettleModeID == '4' || fSettleModeID == '11'" class="logind-1 flex-row-center-center">
              <div id="qrCodeDivID"></div>
            </div>
            <div v-if="fSettleModeID == '12' || fSettleModeID == '19'" class="logind-1 flex-row-center-center">
              <iframe
                :srcdoc="qianHtml"
                frameborder="no"
                border="0"
                marginwidth="0"
                marginheight="0"
                scrolling="no"
                width="168"
                height="168"
                style="overflow: hidden"
              >
              </iframe>
            </div>
          </div>
        </div>
        
        <div v-if="timeRemaining > 0" class="logind-2" style="margin-top: 30px;">
          <p class="lohp">剩余支付时间
            <span style="display: inline-block;">
              <countDowm :showHours='false' :remainTime="(timeRemaining) * 1000" :showBold="false" :height="24" :textFontSize="13" :colonColor="'#333333'" :fontWeight="700" bgColor="#fff" numberColor="#0173FE" :fontSize="14"></countDowm>
            </span>
            
          </p>
        </div>
        <div v-else class="logind-2" style="margin-top: 30px;">
          <p class="lohp">二维码已过期，请重新刷新</p>
        </div>
        <div class="logind-3">
          <p class="lohp">请打开<span style="color: #0173FE;">{{payname}}</span>扫码完成支付</p>
        </div>
        <div class="logind-3">
          <el-button type="primary" @click="AlreadyPaid" style="padding: 12px 70px; margin-top: 10px;">支付完成</el-button>
        </div>
        <div class="logind-2" style="padding-bottom: 20px;">
          <p class="lohp" style="color: #0173FE;"  @click="hasProblem">支付遇到问题?</p>
        </div>
      </div> 
    </el-dialog> -->
  <div class="zhezhao" v-if="wxpayshow" style="background-color: rgba(0,0,0,0.55);"></div>
  <div v-dialogDrag :close-on-press-escape="false" v-show="wxpayshow" class="erweimaBox" width="375px" :custom-class="'custom-dialog'"
      :before-close="paymentWaitingClose" :close-on-click-modal="false" :show-close='false'>
      <div class="erweima">
        <img src="@/assets/zhifu.png" alt="" style="width: 148px; height: 148px;" class="img1">
        <img src="@/assets/zhifuicol.png" alt="" class="img2">
        <img src="@/assets/zhifuicor.png" alt="" class="img3">
        <div class="tit">
          <div style="font-weight: 700; font-size: 17px;">支付金额</div>
          <div style="font-weight: 700; color:#0173FE;">￥{{payData.fTotalMoney|NumFormat}} </div>
        </div>
        <div class="scan-code-box flex-row-center-center" style="margin-top: 30px;">
          <div class="logind flex-row-center-center">
            <div v-if="scanCodeStatus == 4" class="scan-code-error flex-column-center-center">
              <div class="font-size17 font-weight700 font-color-fff" style="position: absolute;">二维码已失效</div>
              
              <img src="@/assets/shandian2.png" alt="" style="width: 100%; height: 100%;">
            </div>
            <div v-if="fSettleModeID == '4' || fSettleModeID == '11'" class="logind-1 flex-row-center-center">
              <div id="qrCodeDivID"></div>
            </div>
            <div v-if="fSettleModeID == '12' || fSettleModeID == '19'" class="logind-1 flex-row-center-center">
              <iframe
                :srcdoc="qianHtml"
                frameborder="no"
                border="0"
                marginwidth="0"
                marginheight="0"
                scrolling="no"
                width="168"
                height="168"
                style="overflow: hidden"
              >
              </iframe>
            </div>
          </div>
        </div>
        
        <div v-if="remainTime > 0" class="logind-2" style="margin-top: 30px;">
          <p class="lohp">剩余支付时间
            <span style="display: inline-block;">
              <countDowm :showHours='false' :remainTime="remainTime" :showBold="false" :height="24" :textFontSize="13" :colonColor="'#333333'" :fontWeight="700" bgColor="#fff" numberColor="#0173FE" :fontSize="14"></countDowm>
            </span>
            
          </p>
        </div>
        <div v-else class="logind-2" style="margin-top: 30px;">
          <p class="lohp">二维码已过期，请重新刷新</p>
        </div>
        <div class="logind-3">
          <p class="lohp">请打开<span style="color: #0173FE;">{{payname}}</span>扫码完成支付</p>
        </div>
        <div class="logind-3">
          <el-button type="primary" @click="AlreadyPaid" :loading="paySuccess" :disabled="paySuccess" style="padding: 12px 70px; margin-top: 10px;">支付完成</el-button>
        </div>
        <div class="logind-2" style="padding-bottom: 20px;">
          <p class="lohp" style="color: #0173FE;"  @click="hasProblem">支付遇到问题?</p>
        </div>
    </div> 
  </div>
  
    <!-- 选择账服通银行 -->
    <getAcsBank v-if="getAcsBankVisible" :getAcsBankVisible="getAcsBankVisible" @change="getAcsBankChange"></getAcsBank>
    <!-- 设置支付密码 -->
    <setPaymentPassword v-if="setPaymentPasswordVisible" :setPaymentPasswordVisible="setPaymentPasswordVisible"
      @change="setPaymentPassword"></setPaymentPassword>
    <Suspension></Suspension>
    <agreementDialog :iContents='iContent' ref="areDialog" :list="AgreementHistList" @handleClick="handleClick">
    </agreementDialog>
  </div>
</template>
<script>document.forms['alipaysubmit'].submit();</script>
<script>
  import {
    mapGetters,
    mapActions
  } from 'vuex';
  import countDowm from '@/components/countDown/countDown.vue'
  import addBankCard from '@/components/payment/addBankCard'
  import getAcsBank from '@/components/payment/getAcsBank'
  import imageBox from '@/components/imageBox/imageBox.vue'
  import setPaymentPassword from '@/components/payment/setPaymentPassword.vue'
  import PasswordInput from '@/components/payment/PasswordInput'
  import agreementDialog from '@/components/agreementDialog';
  import QRCode from 'qrcodejs2';
  export default {
    components: {
      addBankCard,
      getAcsBank,
      imageBox,
      setPaymentPassword,
      PasswordInput,
      agreementDialog,
      countDowm
    },
    data() {
      return {
		showEndBalance: true,
        qianHtml: '',
        aaaa: false,
        isCZ: 0,
        codeUrl: '',
        wxpayshow:false,
        scanCodeStatus: 0,
        expiringInterval: null,
        payname: '',
        timeRemaining: 0,
		dialogVisible:false,
		byecontent:'',//充值异常的提示
        showCountDowm: false,
        orderLoading: false, //支付单加载
        paymentLoading: false, //支付方式加载
        getAcsBankVisible: false, //选择账服通银行卡弹窗
        setPaymentPasswordVisible: false, //设置支付密码弹框
        paymentWaitingVerification: false, //跳转支付弹框
        VerificationCodeLoading: false, //验证码确认按钮加载
        selectZFTBank: {}, //选择账服通支付的银行
        currentIndex: 0,
        PasswordVisible: false, //支付弹窗
        VerificationCodeVisible: false, //验证码弹窗
        paymentList: [], // 支付方式
        fSettleModeID: '', //选择支付方式id
        fEndBalance: '', //选择支付方式余额
        pageTitle: '订单信息',
        orderIds: [],
        payData: {}, // 支付单数据
        payList: [], // 支付单数据明细
        isPay: false, //是否支付
        pageType: 1, //页面显示的类型 1 显示明细 2 只显示标题和金额
        fBillTypeID: '', //单据类型
        billNumber: '', //单据号
        money: '', //充值金额
        shopID: '', //店铺ID
        VerificationCode: '', //手机验证码
        password: '', //支付密码
        fIsCashRecharge: 0, //是否现金钱包充值 0 不是 1 是
        payButtonLoading: false, //按钮加载
        tradingRules: false, //交易规则
        iContent: '', //交易规则
        pwdList: [], //密码数组
        AgreementHistList: [], //协议列表
        choosePayment: {}, //选择支付方式数据
        bankCardList: [], //快捷支付银行卡列表
        selectBankCard: {}, //选择的银行卡
        showBankCardList: false, //选择银行卡弹框
        numberOfPassword: 0, //输入密码次数
        logoUrl: '',
		isTask:false,//判断是否是订单任务的跳转
		needObj:'',
		remainTime: 0,
		canReload: true,
		AccountPayWalletInfo: null,
		paySuccess: false, // 支付完成按钮动画
      };
    },
    activated() {},
    created() {
      var that = this
      //当前页面监视键盘输入
      document.onkeydown = function(e) { //事件对象兼容
        // console.log(e, e.key);
        if (that.PasswordVisible && that.$route.fullPath == '/paymentOrder') {
          if (e.key == '0' || e.key == '1' || e.key == '2' || e.key == '3' || e.key == '4' || e.key == '5' || e.key ==
            '6' ||
            e.key == '7' || e.key == '8' || e.key == '9') {
            if (that.pwdList.length < 6) {
              that.pwdList.push(e.key)
              that.password = that.pwdList.join('')
            }
            if (that.pwdList.length == 6) {
              that.numberOfPassword = that.numberOfPassword + 1;
              that.otherPaymentMethod();
              // that.PasswordVisible = false;
            }
          } else if (e.key == 'Backspace') {
            that.pwdList.pop()
          }
          // console.log(that.pwdList);
        }
      }
    },
    mounted() {
      this.payQRcodeCountDown()
      this.logoUrl = this.$store.getters.getLogoUrl ? this.$store.getters.getLogoUrl : this.weblogUrl;
      /*
      pageType 1 显示明细 2 只显示标题和金额
      fBillTypeID 进入这页面单据类型
      1.fBillTypeID 为 200012002  订单(购物车,待支付订单) B2C
      2.fBillTypeID 为 200016002	订单	(企业购)	B2B
      3.fBillTypeID 为 200013013  派车补量单
      4.fBillTypeID 为 200015019  账服通充值单
      5.fBillTypeID 为 200000905  授信还款单
      6.fBillTypeID 为 200000805  现金钱包充值
      */
      this.fBillTypeID = this.$route.params.fBillTypeID
	  this.needObj = this.$route.params.needObj
	  this.isTask = this.$route.params.isTask
      if (this.fBillTypeID == '200012002' || this.fBillTypeID == "200016002") { //订单支付
        this.pageTitle = "订单信息";
        this.orderIds = this.$route.params.orderIds; //获取支付单id
        this.pageType = 1;
      } else if (this.fBillTypeID == '200013013') { //补量单支付
        this.pageTitle = "补量单信息";
        this.orderIds = this.$route.params.orderIds; //获取支付单id
        this.pageType = 1;
      } else if (this.fBillTypeID == '200015019') { //账服通充值
        this.pageTitle = this.$route.params.title;
        this.money = this.$route.params.money;
        this.pageType = 2;
      } else if (this.fBillTypeID == '200000905') { //授信还款
        this.pageTitle = this.$route.params.title;
        this.money = this.$route.params.money;
        this.shopID = this.$route.params.shopID;
        this.pageType = 2;
      } else if (this.fBillTypeID == '200000805') { //现金钱包充值
        this.pageTitle = this.$route.params.title;
        this.money = this.$route.params.money;
        this.pageType = 2;
        this.fIsCashRecharge = 1;
		 this.fGatherTaskID =  this.$route.params.id;
      }
      this.getTradingRules() //获取协议
      //从VUEX中获取数据判断页面是否刷新
      let isRefresh = this.$store.getters.getRefresh;
      if (!this.$route.params.fBillTypeID && !isRefresh) {
        this.isPay = true;
        this.$router.go(-1)
      } else {
        //监听返回事件
        if (window.history && window.history.pushState) {
          history.pushState(null, null, document.URL);
          window.addEventListener('popstate', this.goBack, false);
        }
        //监听浏览器刷新事件
        window.addEventListener("beforeunload", this.refresh, false);
        //页面刷新从VUEX中获取支付单数据
        if (isRefresh && !this.$route.params.fBillTypeID) {
          this.payData = this.$store.getters.getPayData;
		  const date = new Date(this.payData.fCreateTime);
		  let timestamp  = date.getTime() + 1800000
		  const now = new Date();
		  let nowTime = now.getTime()
		  this.remainTime = timestamp - nowTime
		  console.log(this.remainTime)
          this.payList = this.$store.getters.getPayList;
          // this.paymentList = JSON.parse(JSON.stringify(this.$store.getters.getPayMode));
          this.queryPayment(this.payData.fCustomerPaymentBillID);
          this.pageTitle = this.$store.getters.getParameter.title;
          this.pageType = this.$store.getters.getParameter.pageType;
          this.fBillTypeID = this.$store.getters.getParameter.fBillTypeID;
          this.money = this.$store.getters.getParameter.money;
          console.log(this.payData, '查看先关数据')
          if (this.fBillTypeID == '200000805') {
            this.fIsCashRecharge = 1;
          }
          // this.paymentList.forEach(item => {
          // 	item.checked = false
          // })
          // this.paymentList[0].checked = true;
        } else {
          this.creatPayList();
          let obj = {
            title: this.pageTitle,
            pageType: this.pageType,
            fBillTypeID: this.fBillTypeID,
            money: this.money,
            shopID: this.shopID
          }
          this.$store.commit("setParameter", obj);
        }
      }
    },
    destroyed() {
      //销毁返回监听
      window.removeEventListener('popstate', this.goBack, false);
      //销毁刷新监听
      window.removeEventListener('beforeunload', this.refresh, false);
      if (!this.isPay) {
        if (this.fBillTypeID == '200000805') {
          this.deleteRecharge() //作废充值单
        } else {
          if (this.fSettleModeID != '4') {
            this.deletePayOrder(); //作废支付单
          }
        }
        this.isPay = false;
      }
      //清空VUEX里的数据
      this.$store.commit("setPayData", {});
      this.$store.commit("setPayList", []);
      // this.$store.commit("setPayMode", []);
      this.$store.commit("setRefresh", false);
      this.$store.commit("setParameter", {});
    },
    computed: {
      ...mapGetters(['getThemeName', 'getUserInfo', "getLogoUrl"]),
      ...mapActions(["setPayData", "setPayList", "setPayMode", "setRefresh", 'getPayData', 'getPayList', 'getPayMode',
        'getRefresh', "getParameter", "setParameter", "getHomeURL", "setHomeURL"
      ]),
      themeClass() {
        return `theme-${this.getThemeName}`;
      }
    },
    methods: {
    // 生成二维码
		createQrCode() {
      setTimeout(()=>{
        let refQRID = 'qrCodeDivID';
        let RQDpcument = document.getElementById(refQRID);
        // 重置元素内容
        RQDpcument.innerHTML = '';
        this.QRCode = new QRCode(RQDpcument, {
          width: 168,
          height: 168,   
          text: this.codeUrl, // 'weixin://wxpay/bizpayurl?pr=tJkTBqUzz', // 二维码地址 TODO
          colorDark: '#000', // 二维码颜色
          colorLight: '#fff' // 二维码背景色
        });
      },0)
		},
    // 二维码过期时间倒计时
			payQRcodeCountDown(expiringDate) {
				let _this = this;
				this.payData = this.$store.getters.getPayData;
				const date = new Date(this.payData.fCreateTime);
				console.log('222222222',date, this.payData)
				let timestamp  = date.getTime() + 1800000
				const now = new Date();
				let nowTime = now.getTime()
				this.timeRemaining = timestamp - nowTime
				console.log('11111111111111', this.timeRemaining)
				// 获取倒计时时间秒数
				// let timeRemaining = new Date(expiringDate).getSeconds();
				// this.timeRemaining = 1800
				// 进入轮循  倒计时
				this.expiringInterval = setInterval(() => {
					_this.timeRemaining = _this.timeRemaining - 1000;
					// _this.intervalForWaitResult();
					// 倒计时剩余时间为 0 时 移除倒计时循环
					if (_this.timeRemaining == 0) {
						// 倒计时时间为0 后 将二维码状态置为 4：过期
						_this.scanCodeStatus = 4;
						clearInterval(_this.expiringInterval);
					}
				}, 1000);
			},
		//-返回首页的annual
		returnHomepage(){
			this.$router.push({
				path: "/",
			});
			this.dialogVisible = false
		},
      handleClick(a) {
        console.log('a', a);
        this.iContent = a;
      },
      //返回监听事件
      goBack() {
        console.log('浏览器返回事件');
        this.$confirm('亲,您当前未完成支付, 请尽快完成支付。', '提示', {
          confirmButtonText: '确认离开',
          cancelButtonText: '继续支付',
          type: 'warning'
        }).then(() => {
			if(this.isTask === true){
				this.$router.replace({
					path:'shoppingCart/createTaskOrder',
					query:this.needObj
				})
			}else{
				this.$router.go(-1)
			}
        }).catch(() => {
          history.pushState(null, null, document.URL);
        });
      },
      // 刷新监听事件
      refresh() {
        console.log(456);
      },
      // 作废支付单 ： 当用户放弃支付时 调用接口作废支付单
      deletePayOrder() {
        console.log(123);
        let fCustomerPaymentBillID = ''
        if (this.fBillTypeID == '200000805') {
          fCustomerPaymentBillID = this.payData.fGatheringBillID
        } else {
          fCustomerPaymentBillID = this.payData.fCustomerPaymentBillID
        }
        this.ApiRequestPostNOMess('api/mall/ebsale/customer-payment-bill/is-delete', {
            id: fCustomerPaymentBillID
          })
          .then(res => {
            console.log("作废支付单___完成", res);
          }, error => {});
      },
      //作废钱包充值单
      deleteRecharge() {
        console.log(this.payData.fGatheringBillID,'作废充值单');
		if(this.payData.fGatheringBillID != undefined){
			this.ApiRequestPostNOMess('api/mall/ebsale/gathering-bill/is-delete-list', [{
			    fGatheringBillID: this.payData.fGatheringBillID
			  }])
			  .then(res => {
			    console.log("作废充值单___完成", res);
			  }, error => {});
		}
      },
      //获取协议
      getTradingRules() {
        this.ApiRequestPostNOMess('api/mall/auxiliary/order-rules-read-record/get-trading-rules-new',{
			 "fCustomerPaymentBillID": this.payData.fCustomerPaymentBillID
		})
          .then(res => {
            console.log("获取协议___完成", res);
            this.AgreementHistList = res.obj.list
            if (res.obj.isDefaultSelect == 1) {
              this.tradingRules = true
            }
          }, error => {});
      },
      // 生成支付单种类判断
      creatPayList() {
        this.orderLoading = true;
        if (this.fBillTypeID == '200012002' || this.fBillTypeID == "200016002") { //生成订单支付单
          this.orderPaymentSlip()
        } else if (this.fBillTypeID == '200013013') { //生成补量支付单
          this.replenishmentPaymentSlip()
        } else if (this.fBillTypeID == '200015019') { //生成账服通充值支付单
          this.rechargePaymentSlip()
        } else if (this.fBillTypeID == '200000905') { //生成授信还款单
          this.repaymentPaymentSlip()
        } else if (this.fBillTypeID == '200000805') { //生成现金钱包充值支付单
		  if(this.fGatherTaskID != undefined){
			  this.PaymentBill()
		  }else{
			  this.walletPaymentSlip()
		  }
        }
      },
	  PaymentBill(){
	  	this.ApiRequestPostNOMess('api/mall/ebsale/gathering-bill/create-cpcn-recharge-task',{
	  		  fMoney: this.money,
	  		  fAppTypeID: this.fAppTypeID,
	  		  fGatherTaskID: this.fGatherTaskID
	  	})
	  		.then(res => {
	  			console.log("任务订单充值支付单___完成", res);
	  			this.payData = res.obj;
				const date = new Date(this.payData.fCreateTime);  
				let timestamp  = date.getTime() + 1800000
				const now = new Date();
				let nowTime = now.getTime()
				this.remainTime = timestamp - nowTime
				console.log(this.remainTime)
	  			this.queryPayment(this.payData.fGatheringBillID);
	  			this.$store.commit("setPayData", this.payData);
	  			this.orderLoading = false;
	  		})
			.catch(err => {
				console.log(err,'先拿到err')
				console.log(err.error.message,'=====================')
				this.orderLoading = false
				this.dialogVisible = true
				this.byecontent = err.error.message
				console.log(this.byecontent,'--------------------')
			})
	  },
      //生成订单支付单
      orderPaymentSlip() {
        console.log('生成订单支付单')
        this.ApiRequestPostNOMess('api/mall/ebsale/customer-payment-bill/create-payment-bill', {
            orderIds: this.orderIds,
            fAppTypeID: this.fAppTypeID
          })
          .then(res => {
            console.log("生成支付单___完成", res);
            if (res.message == '') {
              this.payData = res.obj;
			  const date = new Date(this.payData.fCreateTime);
			  let timestamp  = date.getTime() + 1800000
			  const now = new Date();
			  let nowTime = now.getTime()
			  this.remainTime = timestamp - nowTime
			  console.log(this.remainTime)
              this.payList = res.obj.details;
			  this.getTradingRules() //获取协议
              this.queryPayment(this.payData.fCustomerPaymentBillID);
              this.$store.commit("setPayData", this.payData);
              this.$store.commit("setPayList", this.payList);
            } else {
              this.isPay = true
              // 如果失败则提示用户是否继续
              res.message = res.message.split(';');
              let createTipCode = res.message[1];
              let createMessage = res.message[0];
              this.$confirm(createMessage, '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
              }).then(() => {
                if (createTipCode == '001' || createTipCode == '002' || createTipCode == '003' || createTipCode ==
                  '005' ||
                  createTipCode == '006' || createTipCode == '007' || createTipCode == '010' || createTipCode ==
                  '011' ||
                  createTipCode == '012' || createTipCode == '013') {
                  this.$router.replace({
                    path: '/businessme/PaymentOrderList',
                  })
                } else if (createTipCode == '015') {
                  this.$router.replace({
                    path: '/PersonalContent/InvoiceInformation',
                  })
                }
              }).catch(() => {});
            }
            this.orderLoading = false;
          }, error => {
            this.orderLoading = false;
          });
      },
      //生成补量支付单
      replenishmentPaymentSlip() {
        console.log('生成订单支付单')
        this.ApiRequestPostNOMess(
            'api/mall/ebsale/customer-payment-bill/create-customer-payment-bill-by-order-add-record', {
              fOrderAddRecordIds: this.orderIds,
              fAppTypeID: this.fAppTypeID
            })
          .then(res => {
            console.log("生成支付单___完成", res);
            if (res.message == '') {
              this.payData = res.obj;
			  const date = new Date(this.payData.fCreateTime);
			  let timestamp  = date.getTime() + 1800000
			  const now = new Date();
			  let nowTime = now.getTime()
			  this.remainTime = timestamp - nowTime
			  console.log(this.remainTime)
              this.payList = res.obj.details;
			  this.getTradingRules() //获取协议
              this.queryPayment(this.payData.fCustomerPaymentBillID);
              this.$store.commit("setPayData", this.payData);
              this.$store.commit("setPayList", this.payList);
            } else {
              this.isPay = true
              // 如果失败则提示用户是否继续
              res.message = res.message.split(';');
              let createTipCode = res.message[1];
              let createMessage = res.message[0];
              this.$confirm(createMessage, '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
              }).then(() => {
                if (createTipCode == '001' || createTipCode == '002' || createTipCode == '003' || createTipCode ==
                  '005' ||
                  createTipCode == '006' || createTipCode == '007' || createTipCode == '010' || createTipCode ==
                  '011' ||
                  createTipCode == '012' || createTipCode == '013') {
                  this.$router.replace({
                    path: '/businessme/PaymentOrderList',
                  })
                } else if (createTipCode == '015') {
                  this.$router.replace({
                    path: '/PersonalContent/InvoiceInformation',
                  })
                }
              }).catch(() => {});
            }
            this.orderLoading = false;
          }, error => {
            this.orderLoading = false;
          });
      },
      //生成账服通充值支付单
      rechargePaymentSlip() {
        this.ApiRequestPostNOMess('api/mall/ebsale/customer-payment-bill/acs-recharge', {
            fMoney: this.money,
            fAppTypeID: this.fAppTypeID
          })
          .then(res => {
            console.log("生成账服通充值支付单___完成", res);
            this.payData = res.obj;
			const date = new Date(this.payData.fCreateTime);
			let timestamp  = date.getTime() + 1800000
			const now = new Date();
			let nowTime = now.getTime()
			this.remainTime = timestamp - nowTime
			console.log(this.remainTime)
            this.queryPayment(this.payData.fCustomerPaymentBillID);
            this.$store.commit("setPayData", this.payData);
            this.orderLoading = false;
          }, error => {
            this.orderLoading = false;
            this.$message.error(error.error.message)
          });
      },
      //生成授信还款单
      repaymentPaymentSlip() {
        this.ApiRequestPostNOMess('api/mall/ebsale/customer-payment-bill/credit-repayment', {
            fMoney: this.money,
            fAppTypeID: this.fAppTypeID,
            fShopUnitID: this.shopID
          })
          .then(res => {
            console.log("授信还款单___完成", res);
            this.payData = res.obj;
			const date = new Date(this.payData.fCreateTime);
			let timestamp  = date.getTime() + 1800000
			const now = new Date();
			let nowTime = now.getTime()
			this.remainTime = timestamp - nowTime
			console.log(this.remainTime)
            this.queryPayment(this.payData.fCustomerPaymentBillID);
            this.$store.commit("setPayData", this.payData);
            this.orderLoading = false;
          }, error => {
            this.orderLoading = false;
          });
      },
      //生成钱包充值支付单
      walletPaymentSlip() {
        this.ApiRequestPostNOMess('api/mall/ebsale/gathering-bill/create-cpcn-recharge', {
            fMoney: this.money,
            fAppTypeID: this.fAppTypeID
          })
          .then(res => {
            this.isCZ = 1
            console.log("生成钱包充值支付单___完成", res);
            this.payData = res.obj;
			const date = new Date(this.payData.fCreateTime);
			let timestamp  = date.getTime() + 1800000
			const now = new Date();
			let nowTime = now.getTime()
			this.remainTime = timestamp - nowTime
			console.log(this.remainTime)
            this.queryPayment(this.payData.fGatheringBillID);
            this.$store.commit("setPayData", this.payData);
            this.orderLoading = false;
          }, error => {
            this.orderLoading = false;
			this.$message({
			        message: error.error.message,
			        type: 'warning'
			});
          });
      },
      // 获取所有支付方式
      queryPayment(id) {
        this.paymentLoading = true;
        this.ApiRequestPostNOMess('api/mall/ebsale/customer-payment-bill/get-payment-list', {
            fCustomerPaymentBillID: id,
            fAppTypeID: this.fAppTypeID,
            fIsCashRecharge: this.fIsCashRecharge
          })
          .then(res => {
            console.log("获取所有支付方式___完成", res);
            this.paymentList = res.obj;
            this.paymentList = this.paymentList.filter(item => {
              if (item.fSettleModeID != '7' && item.fSettleModeID != '8' && item.fSettleModeID != '13' && item.fSettleModeID != '14' &&
                item.fSettleModeID !=
                '15' && item.fSettleModeID != '16') {
                return item
              }
            })
            if (this.isCZ == 1) { // 充值订单不显示微信支付
              this.paymentList = this.paymentList.filter( item => {
                return item.fSettleModeID != '4'
              })
            }
            console.log(this.paymentList)
            this.paymentList.forEach((item) => {
              item.checked = false;
              //中金网银支付
              if (item.fSettleModeID == '1') {
                item.fSettleMode = '中金支付'
                item.icon = 'icon-zhongjinzhifu'
                item.color = '#2ECD8C'
                item.PaymentMethodType = '网银支付'
              }
              //中金快捷支付
              if (item.fSettleModeID == '2') {
                item.fSettleMode = '中金支付'
                item.icon = 'icon-zhongjinzaixian'
                item.color = '#FF8B42'
                item.PaymentMethodType = '快捷支付'
              }
              //现金钱包支付
              if (item.fSettleModeID == '3') {
                item.fSettleMode = '钱包支付'
                item.icon = 'icon-qianbao'
                item.color = '#FDB221'
              }
              //现金钱包支付
              if (item.fSettleModeID == '4' || item.fSettleModeID == '11') {
                item.fSettleMode = '微信支付'
                // item.icon = 'icon-weixinzhifu1'
                // item.color = '#57BC2F'
              }
              //承兑支付
              if (item.fSettleModeID == '5') {
                item.fSettleMode = '承兑支付'
                item.icon = 'icon-chengduihuipiao-'
                item.color = '#FF8B42'
              }
              if (item.fSettleModeID == '6') {
                item.icon = 'icon-shouxin'
                item.color = '#F77161'
              }
              if (item.fSettleModeID == '9') {
                item.icon = 'icon-qianbao'
                item.color = '#F77161'
                item.fSettleMode = '账服通支付'
                item.PaymentMethodType = '快捷支付'
              }
              if (item.fSettleModeID == '10') {
                item.icon = 'icon-qianbao'
                item.color = '#F77161'
                item.fSettleMode = '账服通支付'
                item.PaymentMethodType = '网银支付'
              }
              if (item.fSettleModeID == '12' || item.fSettleModeID == '19') {
								item.fSettleMode = '支付宝支付'
								item.PaymentMethodType = ''
							}
              if (item.fSettleModeID == '17') {
                item.icon = 'icon-qianbao'
                item.color = '#F77161'
              }
			  
            });
            // this.paymentList[0].checked = true;
            // this.$store.commit("setPayMode", this.paymentList);
			
			
            this.$store.commit("setRefresh", true);
            this.paymentLoading = false;
            this.showCountDowm = true;
          }, error => {
            this.paymentLoading = false;
          });
      },
      //选择账服通银行卡返回数据
      getAcsBankChange(e) {
        this.getAcsBankVisible = e.show;
        if (e.isSelect) {
          console.log(e.selectBank);
          this.selectZFTBank = e.selectBank
        }
        console.log(e);
      },
      //表单提交
      Post(URL, PARAMTERS) {
        console.log('URL', URL);
        console.log('PARAMTERS', PARAMTERS);
        //创建form表单
        var temp_form = document.createElement("form");
        temp_form.action = URL;
        //如需打开新窗口，form的target属性要设置为'_blank'
        temp_form.target = "_blank";
        temp_form.method = "post";
        temp_form.style.display = "none";
        //添加参数
        for (var item in PARAMTERS) {
          var opt = document.createElement("input");
          opt.name = PARAMTERS[item].name;
          opt.value = PARAMTERS[item].value;
          temp_form.appendChild(opt);
        }
        document.body.appendChild(temp_form);
        //提交数据
        temp_form.submit();
      },
      //确认支付接口(去支付)
      otherPaymentMethod() {
        console.log(this.payData)
        this.payButtonLoading = true;
        this.codeUrl = ''
        this.payButtonLoading = true;
        let fCustomerPaymentBillID = ''
        if (this.fBillTypeID == '200000805') {
          fCustomerPaymentBillID = this.payData.fGatheringBillID
        } else {
          fCustomerPaymentBillID = this.payData.fCustomerPaymentBillID
        }
        this.ApiRequestPostNOMess('api/mall/ebsale/customer-payment-bill/pay', {
            fSettleModeID: this.fSettleModeID,
            fWalletPwd: this.password,
            fCustomerPaymentBillID: fCustomerPaymentBillID,
            fAppTypeID: this.fAppTypeID,
            fBankTypeID: this.selectZFTBank.fBankTypeID ? this.selectZFTBank.fBankTypeID : -1,
            fIsCashRecharge: this.fIsCashRecharge, //是否现金钱包充值
            fAccountID: this.selectBankCard.fAccountID ? this.selectBankCard.fAccountID : -1, //快捷支付卡ID
          })
          .then(res => {
            if (this.fSettleModeID == '4' || this.fSettleModeID == '11' || this.fSettleModeID == '12' || this.fSettleModeID == '19') {
              console.log(res)
              this.scanCodeStatus = 1
              this.payname = this.fSettleModeID == '4' || this.fSettleModeID == '11'? '微信' : '支付宝'
              if (this.fSettleModeID == '12' || this.fSettleModeID == '19') {
                  console.log(res.obj.authCode)
                  this.qianHtml = res.obj.authCode
                  setTimeout(() => {
                    this.wxpayshow = true
                    this.aaaa = true
                  },1000)
                  // 跳转用这个
                  // document.querySelector("body").innerHTML = this.qianHtml;
                  // document.forms[0].submit();
              } else {
                this.codeUrl = res.obj.urlParameter.codeUrl
                this.wxpayshow = true
                this.createQrCode()
              }
              // this.payQRcodeCountDown()
              this.payButtonLoading = false
              return
            }
            console.log("支付___完成", res);
			//支付接口是调成功了，但是密码错了，this.isPay还是要赋值成false，以便于离开当前页面调作废支付单接口
            if (res.code == "HengHeCode1000") { //支付成功
              //1 成功，  0是失败，  2是网银请求成功， 3，发送短信验证码成功。
			  this.isPay = true
              if (res.obj.code == "1" || res.obj.code == "4" ) { //支付成功直接跳转
                let successType = ''
                if (this.fBillTypeID == '200012002' || this.fBillTypeID == "200016002" || this.fBillTypeID ==
                  '200013013') {
                  successType = 'ZF' //支付
                } else if (this.fBillTypeID == '200015019' || this.fBillTypeID == '200000805') {
                  successType = 'CZ' //充值
                } else if (this.fBillTypeID == '200000905') {
                  successType = 'HK' //还款
                }
                console.log(this.payData, '查看里面到底有数据没')
                let obj = {
                  successType: successType,
                  amount: this.payData.fTotalMoney,
                  billNumber: this.payData.fBillNumber,
                  type: 1, //type: 单位类型：1金额2金币3积分
                  fBillTypeID: this.fBillTypeID,
                  fCustomerPaymentBillID: this.payData.fCustomerPaymentBillID
                }
				if (res.obj.code == "4") {
					obj.payType = 1
				}
				if (this.fBillTypeID == '200000805') {
					obj.amount = this.payData.fSumMoney
				}
				console.log(obj)
                this.$router.replace({
                  path: '/PaymentResults',
                  query: {
                    data: JSON.stringify(obj)
                  }
                })
              } else if (res.obj.code == "2") { //是网银请求成功跳转外部支付
                this.$message({
                  message: res.message,
                  type: 'success'
                });
                if (this.fSettleModeID == '10') { //账服通网银支付
                  let data = res.obj.urlParameter;
                  let formMess = {
                    name: 'message',
                    value: data.message,
                  }
                  let formMess1 = {
                    name: 'signature',
                    value: data.signature
                  }
                  let arr = [];
                  arr.push(formMess)
                  arr.push(formMess1)
                  this.Post(data.actionUrl, arr)
                } else if (this.fSettleModeID == '1') { //中金网银支付
                  let data = res.obj.urlParameter;
                  let formMess = {
                    name: 'message',
                    value: data.message,
                  }
                  let formMess1 = {
                    name: 'signature',
                    value: data.signature
                  }
                  let arr = [];
                  arr.push(formMess)
                  arr.push(formMess1)
                  this.Post(data.paymentUrl, arr)
                }
                this.paymentWaitingVerification = true;
              } else if (res.obj.code == "3") { //发送短信验证码成功打开验证码
                this.$message({
                  message: res.message,
                  type: 'success'
                });
                console.log("打开验证码");
                this.VerificationCodeVisible = true;
              }
            }else {
				this.isPay = false
              // this.$message({
              // 	message: res.message,
              // 	type: 'warning'
              // });
              // if (res.obj.code == 0) {
              // 	let _this = this
              // 	setTimeout(() => {
              // 		_this.$u.route({
              // 			url: '/PersonalCenter/pages/AccountAll/PayPassword',
              // 			type: 'redirect',
              // 		})
              // 	}, 1000);
              // }
              if (res.obj) {
                if (res.obj.code == 0) {
                  this.$confirm('输入错误密码次数过多，请重置密码或明日再试！', '提示', {
                    confirmButtonText: '去找回',
                    showCancelButton: false,
                    showClose: false,
                    closeOnPressEscape: false,
                    closeOnClickModal: false,
                    type: 'info'
                  }).then(() => {
                    this.$router.replace({
                      path: '/PersonalContent/paymentPassword',
                      query: {
                        type: 1
                      }
                    });
                    this.PasswordVisible = false;
                  }).catch(() => {}); //取消可以选择其他支付方式
                } else {
                  this.$message({
                    message: res.message,
                    type: 'warning',
                    duration: 1000
                  });
                }
              } else {
                this.$message({
                  message: res.message,
                  type: 'warning'
                });
              }
            }
            this.password = '';
            this.pwdList = []
            this.payButtonLoading = false;
          }, error => {
            console.log(error)
            this.$message.error(error.error.message)
            this.payButtonLoading = false;
          })
          .catch(error => {
            console.log(error)
          });
      },
      //去支付弹窗
      PayDialog() {
        if (!this.payButtonLoading) {
          if (!this.tradingRules && this.fBillTypeID != '200015019' && this.fBillTypeID != '200000805') {
            this.$message({
              message: '请先阅读并同意交易规则',
              type: 'warning'
            });
            return
          }
          if (this.fSettleModeID == '') {
            this.$message({
              message: '请选择支付方式',
              type: 'warning'
            });
            return
          }
          if (this.fSettleModeID == '2' || this.fSettleModeID == '9') { //中金快捷支付,账服通快捷支付
            if (this.choosePayment.fAccountStatus == '0') { //0账付通没开
              this.$confirm('亲,您的账户尚未开通账服通,是否去开通?', '提示', {
                confirmButtonText: '去开通',
                cancelButtonText: '取消',
                type: 'info'
              }).then(() => {
				  sessionStorage.ApplicationName = '账服通钱包'
                this.$router.replace({
                  path: '/businessme/AccountPay',
                });
              }).catch(() => {}); //取消可以选择其他支付方式
              return
            } else if (this.choosePayment.fAccountStatus == '1') { //1开户了没绑充值卡
              this.$confirm('亲,您的快捷支付卡还未绑定,是否去绑定?', '提示', {
                confirmButtonText: '去绑定',
                cancelButtonText: '取消',
                type: 'info'
              }).then(() => {
                if (this.fSettleModeID == '2') { //中金快捷支付没有绑卡去现金钱包
					sessionStorage.ApplicationName = '现金钱包'
                  this.$router.replace({
                    path: '/businessme/MoneyDash',
                  });
                } else { //账服通快捷支付没有绑卡去账服通钱包
				sessionStorage.ApplicationName = '账服通钱包'
                  this.$router.replace({
                    path: '/businessme/AccountPay',
                  });
                }
              }).catch(() => {}); //取消可以选择其他支付方式
              return
            }
          }
		  if (this.fSettleModeID == '20') {
			  if (this.choosePayment.fAccountStatus == '1') { //0账付通没开
			    this.$confirm('亲,您的账户尚未开通交易通,是否去开通?', '提示', {
			      confirmButtonText: '去开通',
			      cancelButtonText: '取消',
			      type: 'info'
			    }).then(() => {
			  	  sessionStorage.ApplicationName = '交易通钱包'
			      this.$router.replace({
			        path: '/businessme/JYTwallet',
			      });
			    }).catch(() => {}); //取消可以选择其他支付方式
			    return
			  }
		  }
          //账服通网银支付没选银行
          if (this.fSettleModeID == '10' && !this.selectZFTBank.fBankTypeID) {
            this.$message({
              message: '请选择支付银行',
              type: 'warning'
            });
            return
          } else if (this.fSettleModeID == '10' || this.fSettleModeID == '17') { //账服通网银支付,账服通支付
            if (this.choosePayment.fAccountStatus == '0') { //0账付通没开
              this.$confirm('亲,您的账户尚未开通账服通,是否去开通?', '提示', {
                confirmButtonText: '去开通',
                cancelButtonText: '取消',
                type: 'info'
              }).then(() => {
				sessionStorage.ApplicationName = '账服通钱包'
                this.$router.replace({
                  path: '/businessme/AccountPay',
                });
              }).catch(() => {}); //取消可以选择其他支付方式
              return
            } else {
              if (this.fSettleModeID == '10') {
                this.otherPaymentMethod()
              } else if (this.fSettleModeID == '17') {
                this.getPayPassword();
              }
            }
          } else if (this.fSettleModeID == '3' || this.fSettleModeID == '5' || this.fSettleModeID == '6' || this.fSettleModeID == '20') {
            //现金钱包支付,承兑汇票支付,授信支付,账服通钱包支付先验证有无支付密码
            this.getPayPassword();
          } else if (this.fSettleModeID == '4' || this.fSettleModeID == '11'|| this.fSettleModeID == '12'|| this.fSettleModeID == '19') {
            console.log(this.timeRemaining)
            // 
            
            this.otherPaymentMethod()
          } else {
            this.otherPaymentMethod()
          }
          if (this.fBillTypeID != '200016002' && this.fBillTypeID != '200013013') {
            this.orderRulesReadRecord()
          }
        }
      },
      //查询支付密码
      getPayPassword() {
        this.ApiRequestPostNOMess('api/mall/ebcustomer/baseinfo/exist-walletpwd')
          .then(res => {
              console.log('判断有无支付密码', res);
              if (res.obj == 1) { //有支付密码
                console.log(this.fEndBalance < this.payData.fTotalMoney, this.fEndBalance, this.payData.fTotalMoney);
                if (this.fEndBalance < this.payData.fTotalMoney) { //当余额是小于支付金额时
					if ( this.fSettleModeID == '20' && !this.showEndBalance) {
						this.$message.warning('亲,当前账户余额不足,请充值')
					} else if (this.fSettleModeID == '20' && this.showEndBalance) {
						console.log(111111)
						this.password = '';
						this.pwdList = [];
						this.PasswordVisible = true;
					}
                  if (this.fSettleModeID == '3' || this.fSettleModeID == '17') { //现金钱包和账服通钱包提示并添加跳转
                    if (this.getUserInfo.fCommerceTypeID == 2) {
                      this.$confirm('亲,当前账户余额不足,是否去充值?', '提示', {
                        confirmButtonText: '去充值',
                        cancelButtonText: '取消',
                        type: 'info'
                      }).then(() => {
                        if (this.fSettleModeID == '3') { //现金钱包余额不足跳转到现金钱包充值
						sessionStorage.ApplicationName = '现金钱包'
                          let obj = {
                            type: 'recharge',
                            walletType: 'XJ',
                          }
                          this.$router.replace({
                            path: '/businessme/RechargeWallet',
                            query: {
                              data: encodeURIComponent(JSON.stringify(obj))
                            }
                          });
                          // this.$router.replace({
                          // 	path: '/businessme/MoneyDash',
                          // });
                        } else { //账服通钱包余额不足跳转到账服通钱包充值
						sessionStorage.ApplicationName = '账服通钱包'
                          let obj = {
                            type: "recharge",
                            walletType: "ZFT",
                          };
                          this.$router.replace({
                            path: '/businessme/RechargeWallet',
                            query: {
                              data: encodeURIComponent(JSON.stringify(obj))
                            }
                          });
                          // this.$router.replace({
                          // 	path: '/businessme/AccountPay',
                          // });
                        }
                      }).catch(() => {}); //取消可以选择其他支付方式
                    } else {
                      if (this.choosePayment.fAccountStatus == '1') { //开户了未绑卡
                        this.$confirm('亲,当前账户余额不足,是否前往绑定快捷支付卡?', '提示', {
                          confirmButtonText: '去绑卡',
                          cancelButtonText: '取消',
                          type: 'info'
                        }).then(() => {
                          if (this.fSettleModeID == '3') { //现金钱包支付没有绑卡去现金钱包
						  sessionStorage.ApplicationName = '现金钱包'
                            this.$router.replace({
                              path: '/businessme/MoneyDash',
                            });
                          } else { //账服通快捷支付没有绑卡去账服通钱包
						  sessionStorage.ApplicationName = '账服通钱包'
                            this.$router.replace({
                              path: '/businessme/AccountPay',
                            });
                          }
                        }).catch(() => {
                          return
                        }); //取消可以选择其他支付方式
                      } else {
                        this.$confirm('亲,当前账户余额不足,是否去充值?', '提示', {
                          confirmButtonText: '去充值',
                          cancelButtonText: '取消',
                          type: 'info'
                        }).then(() => {
                          if (this.fSettleModeID == '3') { //现金钱包余额不足跳转到现金钱包充值
						  sessionStorage.ApplicationName = '现金钱包'
                            let obj = {
                              type: 'recharge',
                              walletType: 'XJ',
                            }
                            this.$router.replace({
                              path: '/businessme/RechargeWallet',
                              query: {
                                data: encodeURIComponent(JSON.stringify(obj))
                              }
                            });
                            // this.$router.replace({
                            // 	path: '/businessme/MoneyDash',
                            // });
                          } else { //账服通钱包余额不足跳转到账服通钱包充值
						  sessionStorage.ApplicationName = '账服通钱包'
                            let obj = {
                              type: "recharge",
                              walletType: "ZFT",
                            };
                            this.$router.replace({
                              path: '/businessme/RechargeWallet',
                              query: {
                                data: encodeURIComponent(JSON.stringify(obj))
                              }
                            });
                            // this.$router.replace({
                            // 	path: '/businessme/AccountPay',
                            // });
                          }
                        }).catch(() => {}); //取消可以选择其他支付方式
                      }
                    }
                  } else {
                    if (this.fSettleModeID == '5') { //承兑汇票支付
                      this.$confirm('亲,当前账户余额不足,是否去充值?', '提示', {
                        confirmButtonText: '确定',
                        type: 'info'
                      }).then(() => {
						  sessionStorage.ApplicationName = '票据钱包'
                        this.$router.replace({
                          path: '/businessme/billWallet',
                        });
                      }).catch(() => {}); //取消可以选择其他支付方式
                    } else if (this.fSettleModeID == '6') { //授信支付
                      this.$confirm('亲,当前账户余额不足,是否去申请?', '提示', {
                        confirmButtonText: '确定',
                        type: 'info'
                      }).then(() => {
						  sessionStorage.ApplicationName = '授信额度'
                        this.$router.replace({
                          path: '/businessme/CreditLine',
                        });
                      }).catch(() => {}); //取消可以选择其他支付方式
                    }
                  }
                } else {
                  //打开密码输入框
				  console.log(222222)
                  this.password = '';
                  this.pwdList = [];
                  this.PasswordVisible = true;
                  // if (this.numberOfPassword < 6) {
                  // 	this.password = '';
                  // 	this.pwdList = [];
                  // 	this.PasswordVisible = true;
                  // } else {
                  // 	this.$confirm('密码错误次数已达上限，请找回支付密码!', '提示', {
                  // 		confirmButtonText: '确定',
                  // 		cancelButtonText: '取消',
                  // 		type: 'info'
                  // 	}).then(() => {
                  // 		//打开设置密码弹框
                  // 		this.setPaymentPasswordVisible = true;
                  // 	}).catch(() => {}); //取消可以选择其他支付方式
                  // }
                }
              } else { //没有支付密码提示设置支付密码
                this.$confirm('亲,当前账户未设置支付密码,是否去设置?', '提示', {
                  confirmButtonText: '去设置',
                  cancelButtonText: '取消',
                  type: 'info'
                }).then(() => {
                  //打开设置密码弹框
                  this.setPaymentPasswordVisible = true;
                }).catch(() => {});
              }
            },
            error => {});
      },
      //选择支付方式
      paymentListChoose(item, index) {
        console.log(item, index);
        this.paymentList.forEach(item => {
          item.checked = false;
        });
        this.fSettleModeID = this.paymentList[index].fSettleModeID;
        this.fEndBalance = this.paymentList[index].fEndBalance;
        this.paymentList[index].checked = true;
        this.choosePayment = item;
        this.$forceUpdate()
        if (this.fSettleModeID == '10') { //账服通网银支付打开选择银行弹框
          this.getAcsBankVisible = true;
        }
        if (this.fSettleModeID == '2') { //中金快捷支付
          if (!this.selectBankCard.fBankName) {
            this.getCpcnList() //获取中金快捷支付卡
          } else {
            if (this.bankCardList.length > 0) {
              this.showBankCardList = true;
            }
          }
        }
        if (this.fSettleModeID == '9') { //账服通快捷支付
          if (!this.selectBankCard.fBankName) {
            this.getAcsList() //获取快捷支付卡
          } else {
            if (this.bankCardList.length > 0) {
              this.showBankCardList = true;
            }
          }
        }
		if (this.fSettleModeID == '20') { //交易通
		  if (!this.AccountPayWalletInfo) {
		    this.getJYTList()
		  }
		}
      },
	  // 交易通是否开通
	  getJYTList() {
		   this.ApiRequestPostNOMess('api/mall/ebcustomer/bank-account/get-jyt-virtual-account')
		   .then(res => {
			   if (res.obj != null && res.obj.fCustomerID != null) {
					this.AccountPayWalletInfo = res.obj;
					this.selectBankCard = res.obj;
			   } else {
				   this.$confirm('您还未开通交易通, 是否去开通?', '提示', {
				        confirmButtonText: '确定',
				        cancelButtonText: '取消',
				        type: 'warning'
				    }).then(() => {
						sessionStorage.ApplicationName = '交易通钱包'
				        this.$router.push({
				        	path: '/businessme/JYTwallet',
				        	query: {
				        		title: '交易通钱包',
				        		type: 2,
				        		fAccountname: '开通交易通'
				        	}
				        }) //跳转企业用户绑卡
				    }).catch(() => {});
			   }
		   }, error => {})
	  },
      //获取中金快捷支付卡
      getCpcnList() {
        this.ApiRequestPostNOMess('api/mall/ebcustomer/bank-account/get-cpcn-physical-account-list')
          .then(res => {
            console.log("获取中金快捷支付卡___完成", res);
            if (res.code == "HengHeCode1000") {
              this.bankCardList = res.obj
              if (this.bankCardList.length > 0) {
                this.bankCardList.forEach(item => {
                  item.bankCardWH = item.fAccountNumber.substring(item.fAccountNumber.length - 4);
                  item.checked = false;
                })
                this.selectBankCard = this.bankCardList[0]
                this.bankCardList[0].checked = true;
              }
            }
          }, error => {});
      },
      //获取账服通快捷支付卡
      getAcsList() {
        this.ApiRequestPostNOMess('api/mall/ebcustomer/bank-account/get-acs-physical-recharge-account-list')
          .then(res => {
            console.log("获取账服通快捷支付卡___完成", res);
            if (res.code == "HengHeCode1000") {
              this.bankCardList = res.obj
              if (this.bankCardList.length > 0) {
                this.bankCardList.forEach(item => {
                  item.bankCardWH = item.fAccountNumber.substring(item.fAccountNumber.length - 4);
                  item.checked = false;
                })
                this.selectBankCard = this.bankCardList[0];
                this.bankCardList[0].checked = true;
              }
            }
          }, error => {});
      },
      //选择支付银行卡
      chooseBankCard(item) {
        this.bankCardList.forEach(item1 => {
          item1.checked = false;
        })
        this.selectBankCard = item;
        item.checked = true;
        this.showBankCardList = false;
        console.log(this.selectBankCard);
      },
      //验证码确认
      submitVerification() {
        if (this.VerificationCode == '') {
          this.$message({
            message: '请输入验证码',
            type: 'warning'
          });
          return
        }
        this.VerificationCodeLoading = true;
        this.VerificationCodeVisible = false;
        if (this.fSettleModeID == '9') { //账服通快捷支付短信验证
          this.quickpayMsgZFT();
        } else if (this.fSettleModeID == '2') { //中金快捷支付短信验证
          this.quickpayMsgZJ();
        }
      },
      //账服通快捷支付短信验证
      quickpayMsgZFT() {
        this.ApiRequestPostNOMess('/api/mall/ebsale/customer-payment-bill/check-quickpay-msg', {
            fCustomerPaymentBillID: this.payData.fCustomerPaymentBillID,
            msg: this.VerificationCode,
            fIsCashRecharge: this.fIsCashRecharge //是否现金钱包充值
          })
          .then(res => {
            console.log("账服通快捷支付短信验证___完成", res);
            this.VerificationCodeLoading = false;
            if (res.code == "HengHeCode1000") {
              this.getPayResultZFT(this.payData.fCustomerPaymentBillID)
            } else {
              this.VerificationClose();
              this.$message({
                message: res.message,
                type: 'warning'
              });
              this.rechargePaymentSlipAgain(res.obj.fCustomerPaymentBillID)
            }
          }, error => {});
      },
      //账服通验证码输入错误重新生成支付单
      rechargePaymentSlipAgain(e) {
        this.ApiRequestPostNOMess('api/mall/ebsale/customer-payment-bill/get', {
            id: e
          })
          .then(res => {
            console.log("再次生成账服通充值支付单___完成", res);
            this.payData = res.obj;
			const date = new Date(this.payData.fCreateTime);
			let timestamp  = date.getTime() + 1800000
			const now = new Date();
			let nowTime = now.getTime()
			this.remainTime = timestamp - nowTime
			console.log(this.remainTime)
            this.queryPayment(this.payData.fCustomerPaymentBillID);
            this.$store.commit("setPayData", this.payData);
          }, error => {});
      },
      //中金快捷支付短信验证
      quickpayMsgZJ() {
        let fCustomerPaymentBillID = ''
        if (this.fBillTypeID == '200000805') {
          fCustomerPaymentBillID = this.payData.fGatheringBillID
        } else {
          fCustomerPaymentBillID = this.payData.fCustomerPaymentBillID
        }
        this.ApiRequestPostNOMess('api/mall/ebsale/customer-payment-bill/check-cpcn-quickpay-msg', {
            fCustomerPaymentBillID: fCustomerPaymentBillID,
            msg: this.VerificationCode,
            fIsCashRecharge: this.fIsCashRecharge //是否现金钱包充值
          })
          .then(res => {
            console.log("中金快捷支付短信验证___完成", res);
            this.VerificationCodeLoading = false;
            if (res.code == "HengHeCode1000") {
              if (this.fBillTypeID == '200000805') {
                this.getPayResultZJ(fCustomerPaymentBillID)
              } else {
                this.getPayResultZFT(fCustomerPaymentBillID);
              }
            } else {
              this.$message({
                message: res.message,
                type: 'warning'
              });
            }
          }, error => {});
      },
      //支付弹窗关闭
      handleClose() {
        this.PasswordVisible = false;
      },
      //验证码弹窗关闭
      VerificationClose() {
        this.VerificationCodeVisible = false;
        this.VerificationCode = '';
      },
      //跳转支付弹框关闭
      paymentWaitingClose() {
        this.paymentWaitingVerification = false;
        this.wxpayshow = false
      },
      //选择银行卡弹框关闭
      BankCardListClose() {
        this.showBankCardList = false
      },
      //我已支付
      AlreadyPaid() {
		this.paySuccess = true
        this.isPay = true
        this.qianHtml = ''
        if (this.scanCodeStatus != 0) {
          this.scanCodeStatus = 0
          this.wxpayshow = false
          clearInterval(this.expiringInterval);
        }
        this.paymentWaitingVerification = false;
        let fCustomerPaymentBillID = ''
        console.log(this.payData)
        if (this.fBillTypeID == '200000805') {
          fCustomerPaymentBillID = this.payData.fGatheringBillID
        } else {
          fCustomerPaymentBillID = this.payData.fCustomerPaymentBillID
        }
        this.ApiRequestPostNOMess('api/mall/ebsale/customer-payment-bill/pay-success-validate', {
            fCustomerPaymentBillID: fCustomerPaymentBillID,
            fAppTypeID: this.fAppTypeID,
            fIsCashRecharge: this.fIsCashRecharge
          })
          .then(res => {
            console.log("我已支付___完成", res);
            if (this.fSettleModeID == '10') { //账服通网银支付
              this.getPayResultZFT(fCustomerPaymentBillID);
            } else if (this.fSettleModeID == '1') { //中金网银支付
              if (this.fBillTypeID == '200000805') { //钱包充值
                this.getPayResultZJ(fCustomerPaymentBillID);
              } else {
                this.getPayResultZFT(fCustomerPaymentBillID);
              }
            } else  if (this.fSettleModeID == '4') {
              this.getPayResultZFT(fCustomerPaymentBillID);
            } else  if (this.fSettleModeID == '11') {
              this.getPayResultZJ(fCustomerPaymentBillID);
            } else  if (this.fSettleModeID == '12') {
                this.getPayResultZJ(fCustomerPaymentBillID);
            } else  if (this.fSettleModeID == '19') {
                this.getPayResultZFT(fCustomerPaymentBillID);
            }
			this.paySuccess = false
          }, error => {});
      },
      //订单支付获取支付结果
      getPayResultZFT(e) {
        this.ApiRequestPostNOMess('/api/mall/ebsale/customer-payment-bill/get', {
            id: e
          })
          .then(res => {
             console.log("获取支付结果___完成", res);
            let successType = ''
            if (this.fBillTypeID == '200012002' || this.fBillTypeID == "200016002" || this.fBillTypeID ==
              '200013013') {
              successType = 'ZF' //支付
            } else if (this.fBillTypeID == '200015019' || this.fBillTypeID == '200000805') {
              successType = 'CZ' //充值
            } else if (this.fBillTypeID == '200000905') {
              successType = 'HK' //还款
            }
            let obj = {
              successType: successType,
              amount: this.payData.fTotalMoney,
              billNumber: res.obj.fBillNumber,
              type: 1, //type: 单位类型：1金额2金币3积分
              payType: res.obj.fPlyingStatus,
              fBillTypeID: this.fBillTypeID
            }
            this.$router.replace({
              path: '/PaymentResults',
              query: {
                data: JSON.stringify(obj)
              }
            })
          }, error => {});
      },
      //充值支付结果
      getPayResultZJ(e) {
        this.ApiRequestPostNOMess('api/mall/ebsale/gathering-bill/get', {
            id: e
          })
          .then(res => {
            console.log("获取支付结果___完成", res);
            let successType = ''
            if (this.fBillTypeID == '200012002' || this.fBillTypeID == "200016002" || this.fBillTypeID ==
              '200013013') {
              successType = 'ZF' //支付
            } else if (this.fBillTypeID == '200015019' || this.fBillTypeID == '200000805') {
              successType = 'CZ' //充值
            } else if (this.fBillTypeID == '200000905') {
              successType = 'HK' //还款
            }
            let amount = ''
            if (this.fBillTypeID == '200000805') {
              amount = this.payData.fSumMoney
            } else {
              amount = this.payData.fTotalMoney
            }
            let obj = {
              successType: successType,
              amount: amount,
              billNumber: res.obj.fGatheringBillNumber,
              type: 1, //type: 单位类型：1金额2金币3积分
              payType: res.obj.fPlyingStatus,
              fBillTypeID: this.fBillTypeID
            }
            console.log(JSON.stringify(obj))
            this.$router.replace({
              path: '/PaymentResults',
              query: {
                data: JSON.stringify(obj)
              }
            })
          }, error => {});
      },

      //跳转支付弹框支付遇到问题
      hasProblem() {
        // this.paymentWaitingVerification = false;
        let routerJump = this.$router.resolve({
          path: '/HelpCenter/HelpCenter?id=1',
        });
        window.open(routerJump.href, '_blank');
      },
      //隐藏电话号码中间
      getShowPhone(e) {
        let str = e.toString();
        let arr = str.match(/(\d{3})(\d{4})(\d{4})/).slice(1)
        return arr[0] + "****" + arr[2]
      },
      //设置支付密码
      setPaymentPassword(e) {
        if (e.isSet) {
          console.log('设置成功');
          this.numberOfPassword = 0;
          this.getPayPassword()
        }
        this.setPaymentPasswordVisible = e.show
      },
      //返回首页
      goHome() {
        this.$router.replace({
          path: '/home',
        });
      },
      //帮助中心
      toHelpCenter() {
        this.$router.replace({
          path: '/HelpCenter/Payment',
        });
      },
      //全部订单
      toAllOrder() {
        // history.replaceState(null, null, this.HomeURL);
        this.$router.replace({
          path: '/businessme/AllOrder',
        });
      },
      //展示交易规则
      showAgreeDia(e) {
        this.iContent = e;
        this.$refs.areDialog.show();
      },
      //用户提交订单时将阅读记录新增
      orderRulesReadRecord() {
        let fAgreementHistIDs = []
        this.AgreementHistList.forEach(item => {
          fAgreementHistIDs.push(item.fAgreementHistID)
        })
        let fCustomerPaymentBillID = ''
        if (this.fBillTypeID == '200000805') {
          fCustomerPaymentBillID = this.payData.fGatheringBillID
        } else {
          fCustomerPaymentBillID = this.payData.fCustomerPaymentBillID
        }
        this.ApiRequestPostNOMess('api/mall/auxiliary/order-rules-read-record/create', {
            fAgreementHistIDs: fAgreementHistIDs,
            fOrderID: fCustomerPaymentBillID
          })
          .then(res => {
            console.log("用户提交订单时将阅读记录新增___完成", res);
          }, error => {});
      },
      Refresh(e) {
        console.log('刷新', e)
        if (e.fSettleModeID == '3') {
          this.getMyCash(e) //现金钱包余额
        } else if (e.fSettleModeID == '5') {
          this.getMyAcceptance(e) //承兑汇票余额
        } else if (e.fSettleModeID == '6') {
          this.getMyCreditLimit(e) //授信余额
        } else if (e.fSettleModeID == '17') {
          this.getMyACS(e) //账服通钱包余额
        } else if (e.fSettleModeID == '20') {
			this.getbalance(e) // 交易通余额
		}
      },
	  updateCountdown(secondsRemaining) {
	    // 显示倒计时
	   
	    // 如果还有剩余时间，则递归调用updateCountdown
	    if (secondsRemaining > 0) {
	      setTimeout(() => {
	  		console.log(secondsRemaining)
	        this.updateCountdown(secondsRemaining - 1);
	      }, 1000);
	    } else {
	      // 倒计时结束的操作
	    	this.canReload = true
	  	this.balance = ''
	    }
	  },
	  // 查询交易通余额
	  getbalance(e) {
		if(this.canReload) {
			this.updateCountdown(30)
			e.fEndBalance = 0
			this.fEndBalance = 0
			this.ApiRequestPost('api/mall/ebcustomer/bank-account/get-jyt-account-balance')
			    .then(res => {
					this.showEndBalance = false
					this.canReload = false
					console.log("交易通包余额刷新", res);
					e.fEndBalance = res.obj.avibal;
					this.fEndBalance = res.obj.avibal;
			}, error => {});
		} else {
			this.$message('每30秒可查询余额，请勿频繁操作')
		}
		
	  },
      //现金钱包余额
      getMyCash(e) {
        this.ApiRequestPostNOMess('api/mall/ebbalance/customer-cash/get-my-cash')
          .then(res => {
            console.log("现金钱包余额刷新", res);
            e.fEndBalance = res.obj[0].fEndBalance;
          }, error => {});
      },
      //承兑汇票余额
      getMyAcceptance(e) {
        this.ApiRequestPostNOMess('api/mall/ebbalance/customer-acceptance/get-my-acceptance')
          .then(res => {
            console.log("承兑汇票余额刷新", res);
            e.fEndBalance = res.obj[0].fEndBalance;
          }, error => {});
      },
      //授信余额
      getMyCreditLimit(e) {
        this.ApiRequestPostNOMess('api/mall/ebbalance/customer-credit-limit/my-credit-limit')
          .then(res => {
            console.log("授信余额刷新", res);
            e.fEndBalance = res.obj[0].fEnableUseMoney;
          }, error => {});
      },
      //账服通余额
      getMyACS(e) {
        this.ApiRequestPostNOMess('api/mall/ebbalance/customer-acs/get-my-ACS')
          .then(res => {
            console.log("账服通余额刷新", res);
            e.fEndBalance = res.obj.fEndBalance;
          }, error => {});
      },
      countDownEnd() {
        console.log('倒计时结束');
        clearInterval(this.expiringInterval);
        if (this.fBillTypeID == '200016002') {
          //企业购订单跳转至企业购
          this.$router.replace({
            path: '/businessBuy',
          })
        } else if (this.fBillTypeID == '200013013') {
          //派车补量单跳转至派车补量页面
          this.$router.replace({
            path: '/businessme/CanPayReplenished',
          })
        } else if (this.fBillTypeID == '200015019') {
          //账服通充值跳转至账服通钱包
          this.$router.replace({
            path: '/businessme/AccountPay',
          })
        } else if (this.fBillTypeID == '200000905') {
          //授信还款跳转至授信钱包
          this.$router.replace({
            path: '/businessme/CreditLine',
          })
        } else if (this.fBillTypeID == '200000805') {
          //现金钱包充值跳转至现金钱包
          this.$router.replace({
            path: '/businessme/MoneyDash',
          })
        } else {
          //商品订单跳转至商品页面
          this.$router.replace({
            path: '/Goods',
          })
        }
      }
    }
  };
</script>

<style lang="scss" scoped>
  .color-theme {
    @include themify($themes) {
      color: themed('themes_color');
    }
  }

  .background-color-theme {
    @include themify($themes) {
      background-color: themed('themes_color');
      color: themed('text_color_white');
    }
  }

  .border-theme {
    @include themify($themes) {
      border: 1px solid themed('themes_color');
    }
  }

  .button-box {
    width: 174px;
    height: 38px;
  }

  .button-new-card {
    background-color: #FFFFFF;
  }

  .button-delete {
    border: 1px solid #999;
    color: #666;
  }

  .boxStyle {
    // width: 900px;
    // min-height: 547px;
    border-radius: 10px;
    box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.149019607843137);
    background-color: #ffffff;
    margin-top: 14px;
    margin-bottom: 60px;
  }

  .payWXQQ {
    margin-top: 58px;
    margin-bottom: 60px;
    width: 900px;
    min-height: 80px;
    border-radius: 10px;
    box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.149019607843137);
    background-color: #ffffff;
    margin-left: 111px;
  }

  .login-t {
    font-weight: 700;
    font-size: 18px;
    // line-height: 80px;
    min-height: 80px;
    min-width: 1100px;
    max-width: 1100px;
    margin: 0 auto;
    display: flex;
    align-items: center;
    /*垂直居中*/
    background-color: #ffffff;
  }

  .loginh {
    margin-top: 80px;
    background: url(../assets/imgs/payBackground.jpg);
    width: 100%;
    background-repeat: no-repeat;
    background-size: cover;
    opacity: 1;
    position: absolute;
    left: 0;
    top: 0;
    z-index: -999;
  }

  .login-c {
    // min-height: 1050px;
    width: 1100px;
    margin: 0 auto;
  }

  /* 单选地址 */
  .price-box.active {
    // width: 855px;
    min-height: 66px;

    @include themify($themes) {
      border: 1px solid themed('themes_color');
    }

    margin: 11px 22px;
    position: relative;
    transition: all 0.5s ease;
  }

  .price-box.none {
    // width: 855px;
    min-height: 66px;
    margin: 11px 22px;
    position: relative;
    border: 1px solid #dfdfdf;
  }

  .capsule {
    width: 144px;
    min-height: 35px;
    border-radius: 0px 100px 100px 0px;
  }

  .ys-verification {
    margin-top: 15px;
    display: flex;

    .input-wrapper {

      input {
        width: 39px;
        min-height: 37px;
        text-align: center;
        font-weight: 700;
      }
    }
  }

  .price-box-bankcard.none {
    width: 385px;
    min-height: 50px;
    margin-left: 15px;
    margin-right: 15px;
    position: relative;
    border: 1px solid #F2F2F2;
  }

  ::v-deep .el-dialog__body {
    padding: 0px 5px;
  }

  .button-box-padding {
    padding: 20px 20px 40px 20px;
  }

  .dialogScroll {
    overflow-y: auto;
    padding-right: 10px;
    overflow-x: hidden;
  }

  ::v-deep .el-input__inner {
    height: 45px !important;
  }

  ::v-deep .el-input-group__append {
    border-radius: 0 !important;
    background-color: #FFFFFF;
  }

  .font-color-E30000 {
    color: #e30000;
  }

  .bg-E30000 {
    background-color: #e30000;
    color: #ffffff;
  }


  ::v-deep .el-checkbox__inner {
    border-radius: 10px;
  }

  ::v-deep .el-checkbox__input.is-checked .el-checkbox__inner,
  .el-checkbox__input.is-indeterminate .el-checkbox__inner {
    @include themify($themes) {
      background-color: themed('themes_color');
      border-color: themed('themes_color');
    }
  }

  .bill-box {
    height: 45px;
    margin: 5px 13px;
    padding: 0px 10px;
  }

  .acceptanceBox {
    margin: 5px 13px;
    width: 635px;
    height: 45px;
    border: 1px solid #f2f2f2;
  }

  .acceptanceBoxChecked {
    margin: 5px 13px;
    width: 635px;
    height: 45px;

    @include themify($themes) {
      border: 1px solid themed('themes_color');
      color: themed('themes_color');
    }
  }

  .inbox {
    line-height: 45px;
    margin-left: 15px;
    margin-right: 15px;
  }

  ::v-deep .el-input-group__append {
    border-radius: 0 !important;
    border-right: 1px solid #F2F2F2;
    border-top: 1px solid #F2F2F2;
    border-bottom: 1px solid #F2F2F2;
    background-color: #FFFFFF;
    min-width: 70px;
    text-align: center;
  }

  .reacharge-or-refund-box {
    height: 70px;
    background-color: #F8F9FE;
  }

  .union-pay {
    width: 20px;
    height: 20px;
  }

  ::v-deep .el-dialog__title {
    font-size: 16px;
  }

  .password-box {
    width: 405px;
    height: 50px;
  }

  .pay-center {
    width: 69px;
    height: 22px;
    margin-left: 25px;
    user-select: none;
  }

  .success-pay-button {
    width: 160px;
    height: 40px;
    user-select: none;
  }

  .payment-waiting-icon {
    width: 30px;
    height: 30px;
    background-color: #ff7777;
    border-radius: 50%;
  }

  .font-color-ff7777 {
    color: #ff7777;
  }

  .box-input-div {
    width: 40px;
    height: 40px;
  }

  .box-input-div-choose-style {
    border: 1px solid #B9D9FF;
    box-shadow: 0 0 10px rgba(1, 115, 254, 0.3);
  }

  .box-input-div-style {
    border: 1px solid #e4e4e4;
  }

  .bank-icon {
    .svg-icon {
      width: 34px !important;
      height: 34px !important;
    }
  }
</style>

<style lang="scss">
  .el-button--primary {
    @include themify($themes) {
      border-color: themed('themes_color');
      background-color: themed('themes_color');
      color: themed('text_color_white');
    }
  }
  .com-btn {
  	width: 160px;
  	height: 40px;
  }
  .flexUD {
  	display: flex;
  	align-items: center;
  	/*垂直居中*/
  	justify-content: center;
  	/*水平居中*/
  }
  .buttonStyle {
  	width: 160px;
  	height: 40px;
  	background-color: #ffffff;
  	border-radius: 2px;
  }
  .form-label-mark {
  	width: 12px;
  	height: 12px;
  	border-radius: 50%;
  	border: 1px solid #ccc;
  	font-size: 12px;
  	color: #ccc;
  	cursor: pointer;
	margin-left: 10px;
	margin-right: 10px;
  }
  .custom-dialog {
    border-radius: 15px;
    position: relative;
    .img1 {
      position: absolute;
      top: -50px;
      left: 50%;
      transform: translateX(-50%);
    }
    .img2 {
      position: absolute;
      top: 0;
      left: 0;
    }
    .img3 {
      position: absolute;
      bottom: 0;
      right: 0;
    };
  }
  .zhezhao {
    width: 100%;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    overflow: auto;
    margin: 0;
    z-index: 1000;
  }
  .hiddenpage {
    overflow: hidden;
    height: calc(100% - 97px);
  }
  .erweimaBox {
    width: 375px; 
    height: 545px;
    border-radius: 15px; 
    position: relative; 
    top: 0; 
    margin: 0 auto 50px; 
    z-index: 1000;
    /* margin-top: 15vh;  */
    background-color: #fff;
    .img1 {
      position: absolute;
      top: -50px;
      left: 50%;
      transform: translateX(-50%);
    }
    .img2 {
      position: absolute;
      top: 0;
      left: 0;
    }
    .img3 {
      position: absolute;
      bottom: 0;
      right: 0;
    };
  }
  .erweima {
    margin-top: 65px;
    padding-top: 95px;
    .tit {
      width: 100%;
      text-align: center;
      font-size: 30px;
      /* padding-bottom: 10px; */
    }
    .logind {
      position: relative;
    }
    .logind-1 {
      padding: 6px;
      width: 168px;
      height: 168px;
      border: 1px solid #e4e4e4;
    }
    .logind-2 {
      margin-top: 10px;
      font-size: 13px;
      text-align: center;
    }
    .logind-3 {
      /* margin-top: 10px; */
      font-size: 13px;
      text-align: center;
    }
    .lohp {
      margin-top: 0;
      margin-bottom: 0;
      height: 24px;
      ;line-height: 24px;
    }
    .scan-code-error {
      position: absolute;
      width: 168px;
      height: 168px;
      background-color: rgba($color: #000000, $alpha: 0.5);
    }
  }
</style>
